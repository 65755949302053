<template>
  <div class="view pa24">
    <el-button
        class="mr10"
        type="primary"
        @click="add"
    >+ 添加开发区</el-button>
    <commonTable
        :tableData="tableData"
        :loading="loading"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :total="total"
    >
      <template v-slot:table>
        <el-table-column type="selection" width="50"/>
        <el-table-column prop="kfqName" align="center" label="开发区名称" show-overflow-tooltip/>
        <el-table-column prop="address" align="center" label="地址" show-overflow-tooltip/>
        <el-table-column prop="userName" align="center" label="绑定会员" show-overflow-tooltip/>
        <el-table-column align="center" label="排序">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="数值越大，排序越靠前" placement="right-start">
              <el-input v-model="scope.row.sort"  min="0" @input="scope.row.sort=scope.row.sort.replace(/^0(0+)|[^\d]+/g,'')"  placeholder="数值越大，排序越靠前" @change="saveSort(scope.row)"/>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="200">
          <template slot-scope="scope">
            <el-button
                type="text"
                @click="edit(scope.row)"
            >编辑</el-button
            >
            <customPopconfirm
                confirm-button-text="确定"
                k              cancel-button-text="取消"
                @confirm="del(scope.row)"
                icon="el-icon-info"
                icon-color="red"
                title="确定要删除吗？">
              <el-button
                  slot="reference"
                  class="ml10"
                  style="color: #fd634e"
                  type="text"
              >删除</el-button
              >
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { mapState } from 'vuex';
import customPopconfirm from "@/components/common/customPopconfirm";
import {queryAttractInvestmentKFQPage,updateAttractInvestmentKFQ,delAttractInvestmentKFQ} from "@/api/chinaMerchants";
let ac;
export default {
  name:'developmentZone',
  data() {
    return {
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      goodsList: [],
      goodsListCurrentPage: 1, //当前页
      goodsListPageSize: 10, //显示条数
      goodsListLoading: false, //表格加载
      goodsListTotal: 0, //总条数
    };
  },
  components: {
    commonTable,
    customPopconfirm
  },
  computed: {
    ...mapState(['routerArr']),
  },
  mounted(){
    this.queryPage();
  },
  methods: {
    async queryPage() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
      };
      try {
        this.loading = true;
        const result = await queryAttractInvestmentKFQPage(data);
        this.loading = false;
        const { total, list } = result.data.pageInfo;
        for (let i in list) {
        }
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.tableData = []
        this.loading = false;
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPage();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryPage();
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    edit(val) {
      let data = JSON.parse(JSON.stringify(val));
      this.$router.push({ path:`/addFKQ`,query:{kfqId:data.kfqId} });
    },
    /**@method 删除
     * @param {String} val - 文章id
     */
    async del(val) {
      try {
        await delAttractInvestmentKFQ({ kfqId: val.kfqId });
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },
    async saveSort(row){
      if(row && row.sort != ""){
        let ajax = {
          orderBy:row.orderBy,
          rotationId:row.rotationId
        }
        const result = await updateAttractInvestmentKFQ(ajax);
        console.log("result:",result)
        this.$message({
          type: "success",
          message: "保存成功",
        });
        await this.queryPage();
      }else{
        this.$message({
          type: "error",
          message: "排序不能为空",
        });
      }
    },
    add(){
      this.$router.push({ path:`/addFKQ` });
    }
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 78px;
  height: 78px;
  //line-height: 175px;
  text-align: center;
}
/deep/ .avatar-coverImg .el-upload--text {
  width: 100px;
  height: 100px;
}
/deep/ .avatar-coverImg .avatar-uploader-icon {
  line-height: 100px;
}
.select-goods-list {
  margin-bottom: 20px;
  border-radius: 40px;
  box-sizing: border-box;
  padding: 0 20px;
  background: #ffffff;
  transition: all 0.4s;
}
.select-goods-list:hover {
  background: #f5f5f6;
  transition: all 0.4s;
  cursor: pointer;
}
/deep/ .disabled .el-upload--picture-card {
  display: none;
}
</style>





